<template>
  <v-container fluid pa-0>
    <v-card>
    <v-row no-gutters>
      <v-col cols="3" md="3" lg="3" sm="3" class="pa-1 green lighten-5">
        <v-card elevation-0 flat class="green lighten-5">
          <v-card-title class="justify-center text-center white" >
            <v-row no-gutters >
              <v-col cols="12"><v-icon large color="green darken-2">mdi-domain</v-icon></v-col>
              <v-col cols="12" class="mt-2"><p class="display-1 black--text mt-0">Organization</p></v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              <router-link  class="blue--text" to="/settings/org-profile">Organization Profile</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" lg="3" sm="3" class="pa-1 deep-purple lighten-5">
        <v-card elevation-0 flat class="deep-purple lighten-5">
          <v-card-title class="justify-center text-center white" >
            <v-row no-gutters >
              <v-col cols="12"><v-icon large color="deep-purple darken-2">developer_board</v-icon></v-col>
              <v-col cols="12" class="mt-2"><p class="display-1 black--text mt-0">Meters</p></v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              <router-link  class="blue--text" to="/settings/manage-devices/add-meter">Add Meter</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" lg="3" sm="3" class="pa-1 orange lighten-5">
        <v-card elevation-0 flat class="orange lighten-5">
          <v-card-title class="justify-center text-center white" >
            <v-row no-gutters >
              <v-col cols="12"><v-icon large color="orange darken-2">people</v-icon></v-col>
              <v-col cols="12" class="mt-2"><p class="display-1 black--text mt-0">Customer</p></v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              <router-link  class="blue--text" to="/new/customer">Add Customer</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" lg="3" sm="3" class="pa-1 light-blue lighten-5">
        <v-card elevation-0 flat class="light-blue lighten-5">
          <v-card-title class="justify-center text-center white" >
            <v-row no-gutters >
              <v-col cols="12"><v-icon large color="light-blue darken-2">assignment</v-icon></v-col>
              <v-col cols="12" class="mt-2"><p class="display-1 black--text mt-0">Invoice</p></v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              <router-link  class="blue--text" to="/invoices/new">Create New Invoice</router-link>
            </p>
            <p>
              <router-link  class="blue--text" to="/settings/manage-Tariffs">Manage Tariffs</router-link>
            </p>
            <p>
              <router-link  class="blue--text" to="/new/tariff">Add Tariff</router-link>
            </p>
             <p>
              <router-link  class="blue--text" to="/settings/manage-custom-fields">Manage Custom Fields</router-link>
            </p>
            <p>
              <router-link  class="blue--text" to="/new/customfield">Add Custom Fields</router-link>
            </p>
            <p>
              <router-link  class="blue--text" to="/settings/manage-invoice-templates">Manage Invoice Templates</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-card>
  </v-container>
</template>

<script>
///SET A ROUTER VIEW for zero loc entity view
export default {
  components: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      var self = vm;
      vm.$store.dispatch({
        type: "MDMStore/getOrgDetails"
      });
    });
  },
  mounted() {},
  computed: {},
  mounted() {},
  created() {}
};
</script>
<style scoped>
a {
  text-decoration:none !important;
}
</style>
